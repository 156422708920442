export class ValidationError extends Error {
	constructor(code = 'VALIDATION', status = 400, detail = [], ...params) {
	    super(...params)

	    if (Error.captureStackTrace) {
	        Error.captureStackTrace(this, ValidationError)
	    }

	    this.code = code;
	    this.status = status;
	    this.detail = detail;
	}	
};

export class HttpError extends Error {
	constructor(code = 'HTTP', status = 500, ...params) {
	    super(...params)

	    if (Error.captureStackTrace) {
	        Error.captureStackTrace(this, HttpError)
	    }

	    this.code = code;
	    this.status = status;
	}	
};







