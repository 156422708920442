import React from 'react';
import { useTranslation } from 'react-i18next';

const Feedback = props => {

	const { t } = useTranslation();

	if (!props.feedback) {
		return null
	}
	
	let type = (props.type) ? props.type : "danger";
	
	return (
		<h5 className={"p-3 text-center fs-4 text-white bg-"+type}  >{(!props.translate) ? props.feedback : t(props.feedback)}</h5>
	)
};

export default Feedback;