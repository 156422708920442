import React from "react";
import { Trans, useTranslation} from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

import '../styles/TransformCustomerExperience.css';

import image1 from '../images/transform-cx.jpeg';

	
const TransformCustomerExperience = props => {
	
	const { t } = useTranslation();
	
	return (
		<Container fluid id="transform-customer-experience">
     		  <Row className="pt-5 justify-content-center mb-3" md={"auto"}><Col md={7} className="d-flex justify-content-center">
     		  		<h2 className="fw-bold display-4 text-center title"><Trans i18nKey="home.transform-customer-experience.title" /></h2>
     		  </Col></Row>
     		  
     		  <Row className="mb-5 justify-content-center"><Col md={8} className="d-flex justify-content-center">
     		  		<p className="fs-3 lead text-center paragraph"><Trans i18nKey="home.transform-customer-experience.paragraph1" /></p>
     		  </Col></Row>
     		  
     		  <Row className="pb-5 justify-content-center"><Col md={6} className="d-flex justify-content-center">
     		  		<Image src={image1} alt={t("home.transform-customer-experience.title")} rounded fluid/>
     		  </Col></Row>

    	</Container>
  );
  
}

export default TransformCustomerExperience;
