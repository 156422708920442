import React, {useState, useEffect}  from 'react';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';

const DismissableFeedback = props => {

	const [timeoutId, setTimeoutId] = useState(null);
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (timeoutId) clearTimeout(timeoutId);
		
		let tId = setTimeout(() => { if(props.feedback && props.onClose) props.onClose(); }, 5000)
		setTimeoutId(tId);
		
		 return () => { isMounted = false };
		  
	}, [props.feedback]);

	if (!props.feedback) {
		return null
	}
	
	
	return (
		<Alert variant={(props.type) ? props.type : "danger"} dismissible onClose={props.onClose}>{(!props.translate) ? props.feedback : t(props.feedback)}</Alert>
	)
};

export default DismissableFeedback;