import React,  { Fragment, useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
//import { useLocation, useNavigate } from 'react-router-dom';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';

import FormControlErrors from "../components/FormControlErrors";
import PrivacyPolicyModal from "../components/PrivacyPolicyModal";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const TryItOutFormFields = props => {
	const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
  	// Create an event handler so you can call the verification on button click event or form submit
  	const handleReCaptchaVerify = useCallback(async () => {
    	if (!executeRecaptcha) {
      		log.warn('Execute recaptcha not yet available');
      	    return;
        }

	   const action = "/home"
       const token = await executeRecaptcha(action);
       
       // Verify Recaptcha
       API.verifyRecaptcha({action: action, token: token}, config.csrfToken)
    		.then((response) => {
    				props.setFieldValue("recaptchaScore", response.score)
    		})
    		.catch(error => { });
       
    }, [executeRecaptcha]);
    
    useEffect(() => {
		let isMounted = true; 
		
		handleReCaptchaVerify();
		
		return () => { isMounted = false };
		  
	}, [handleReCaptchaVerify]);
	
	const handleAcceptPrivacyPolicyChange = (e) => {
        props.setFieldValue("agreedPrivacyPolicy", e.target.checked);

    }
    
	return(
		<Fragment>
			
			{/*<GoogleReCaptcha onVerify={(token) => {
				// Verify Recaptcha
       			API.verifyRecaptcha({action: "/home", token: token}, config.csrfToken)
    				.then((response) => {
    					console.log(response);	
    					//props.setFieldValue("score", response.score)
    				})
    				.catch(error => { });
				}} />*/}
					  	
			{(showPrivacyPolicyModal) &&
				<PrivacyPolicyModal 
					show={showPrivacyPolicyModal} 
					onHide={() => {setShowPrivacyPolicyModal(false);}}
					size={"lg"}
					//onError={context.onError}
				/>
			}
		      		
						<Row>
							<Col className="p-3" md={6}>
								<Row className="mb-3">
									<FormGroup as={Col} controlId="formGridName">
										<FormLabel><Trans i18nKey="home.try-it-out.form.name.label">Name</Trans></FormLabel>
				   						<FormControl type={'text'} name="name" isInvalid={!(props.errors.name == null)} value={props.values.name} onChange={props.onChange} placeholder={t('home.try-it-out.form.name.placeholder')} />
				    					<FormControlErrors errors={props.errors.name} />
									</FormGroup>
								</Row>
								<Row className="mb-3">
									<FormGroup as={Col} controlId="formGridEmail">
										<FormLabel><Trans i18nKey="home.try-it-out.form.email.label">Email</Trans></FormLabel>
				   						<FormControl type={'email'} name="email" isInvalid={!(props.errors.email == null)} value={props.values.email} onChange={props.onChange} placeholder={t('home.try-it-out.form.email.placeholder')} />
				    					<FormControlErrors errors={props.errors.email} />
									</FormGroup>
								</Row>
								{/*<Row className="mb-3">
									<FormGroup as={Col} controlId="formGridPhone">
										<FormLabel><Trans i18nKey="home.try-it-out.form.phone.label">Phone</Trans></FormLabel>
				   						<FormControl type={'text'} name="phone" isInvalid={!(props.errors.phone == null)} value={props.values.phone} onChange={props.onChange} placeholder={t('home.try-it-out.form.phone.placeholder')} />
				    					<FormControlErrors errors={props.errors.phone} />
									</FormGroup>
								</Row>*/}
								
								<Row className="mb-3">
									<FormGroup as={Col} controlId="formGridPhone">
			    						<FormLabel ><Trans i18nKey="home.try-it-out.form.phone.label">Phone</Trans></FormLabel>
										<PhoneInput
  											placeholder={t('home.try-it-out.form.phone.placeholder')}
  											value={props.values.phone}
  											onChange={(value) => {props.setFieldValue("phone", value);}}
  											isInvalid={!(props.errors.phone == null)}
  											defaultCountry="BR"
  											international
  											countryCallingCodeEditable={false}
  											name="phone"
  											inputComponent={FormControl}
  										/>
  										<FormControlErrors block={true} errors={props.errors.phone} />
			    					</FormGroup>
			    				</Row>
							</Col>

							<Col className="p-3" md={6}>
								<Row className="mb-3">
									<FormGroup as={Col} controlId="formGridMessage">
										<FormLabel><Trans i18nKey="home.try-it-out.form.message.label">Message</Trans></FormLabel>
				   						<FormControl name="message" as="textarea" rows={4} maxLength={1000} isInvalid={!(props.errors.message == null)} value={props.values.message} onChange={props.onChange} placeholder={t('home.try-it-out.form.message.placeholder')} />
				    					<FormControlErrors errors={props.errors.message} />
									</FormGroup>
								</Row>
								<Row className="mb-3">
									<FormGroup as={Col} controlId="formGridAgreedPrivacyPolicy">
										<FormCheck type="checkbox" name="agreedPrivacyPolicy" >
            								<FormCheck.Input size="lg" type="checkbox" checked={props.values.agreedPrivacyPolicy} isInvalid={!(props.errors.agreedPrivacyPolicy == null)} onChange={handleAcceptPrivacyPolicyChange} />
            								<FormCheck.Label className={!(props.errors.agreedPrivacyPolicy == null) ? "text-danger" : ""}><Trans i18nKey="home.try-it-out.form.agreedPrivacyPolicy.label">Accept Privacy Policy</Trans> <Button variant="link" className="link" onClick={() => {setShowPrivacyPolicyModal(true); }}><Trans i18nKey="home.try-it-out.form.agreedPrivacyPolicy.link">Accept Privacy Policy</Trans></Button></FormCheck.Label>
          								</FormCheck>
          								<FormControlErrors block={true} errors={props.errors.agreedPrivacyPolicy} />
									</FormGroup>
								</Row>
								<Row className="mb-3">
									<Col>
    									<Button className="float-end send-button" size="lg" type="submit" disabled={props.isSubmitting}> 
    										{props.isSubmitting ? <Trans i18nKey="home.try-it-out.form.submitting">Please wait...</Trans> : <span><Trans i18nKey={"home.try-it-out.form.submit"}>Submit</Trans></span>} 
    									</Button>
    					   			</Col>
								</Row>
							</Col>
						</Row>
		
		</Fragment>
		
	) 
}


export default TryItOutFormFields;
