import React, { Fragment, useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
//import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Header from "../components/Header";
import Footer from "../components/Footer";

import { ValidationError } from "../helpers/custom-errors";

import '../styles/NotFoundPage.css';

const NotFoundPage = props => {
   const [_error, _setError] = useState(null);
   const [_success, _setSuccess] = useState(null);
   const [_warning, _setWarning] = useState(null);
   const [_info, _setInfo] = useState(null);
  
   let location = useLocation();
   const { t } = useTranslation();

  const onError = (error) => { 
	 if (!error) {
		 _setError(null);
		 return;
	 }
	 
	 if (error instanceof ValidationError) {		
		 _setError(error.message);
	 } else {
		_setError(t('error.api.general'));
	}
 };
 
 const onSuccess = (success) => {
	_setSuccess(success);
 };

const onWarning = (warning) => {
	_setWarning(warning);
 };

const onInfo = (info) => {
	_setInfo(info);
 };

  return (
	 <Fragment>
		
		<Header 
			onError={onError}
			onSuccess={onSuccess}
			onWarning={onWarning}
			onInfo={onInfo}
		/>
		
		<Container id="not-found-page-title" fluid className="p-4">
		<Container  >
			<Row>
				<Col>
					<h2 className="text-uppercase"><Trans i18nKey="page-not-found.title">Page Not Found</Trans></h2>
				</Col>
				{/*<Col>
					<Breadcrumb>
  						<Breadcrumb.Item href="#" >
							<p className="text-uppercase"><Trans i18nKey="page-not-found.home">Home</Trans></p>
						</Breadcrumb.Item>
						<Breadcrumb.Item active>
							<p className="text-uppercase"><Trans i18nKey="page-not-found.title">Page Not Found</Trans></p>
						</Breadcrumb.Item>
					</Breadcrumb>
				</Col>*/}
			</Row>
		</Container>
		</Container>
		
		<Container id="not-found-page-content" fluid className="p-5">
			<Container className="d-flex flex-column justify-content-center ">
          		
          		<Row className="p-3"><Col>
          			<h2 className="header text-center"><Trans i18nKey="page-not-found.header">404</Trans></h2>
          		</Col></Row>
			
				<Row className="mb-3"><Col>
          			<p className="fs-4 text-center"><Trans i18nKey="page-not-found.message">No match for <code>{location.pathname}</code></Trans> </p>
          		</Col></Row>
			
				<Row><Col className="text-center" >
          			<Button as={Link} to="/"><Trans i18nKey="page-not-found.back-to-home-button">Back to Home</Trans></Button>
          		</Col></Row>
			</Container>
		</Container>
		
		<Footer/>
	</Fragment>
		
  );
}

export default NotFoundPage;