import React from "react";
import { Trans} from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../styles/WhyJoinUs.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faCartShopping, faThumbsUp, faGlobe,  faLeaf, faCommentsDollar } from '@fortawesome/free-solid-svg-icons'

const reasonsRow1 = [
	  {
	    content: 'home.why-join-us.reason1.content',
	    icon: faChartLine,
	  },
	  {
	    content: 'home.why-join-us.reason2.content',
		 icon: faCartShopping,
	  },
	  {
	     content: 'home.why-join-us.reason3.content',
		 icon: faThumbsUp,
	  }
	];
	
	
const reasonsRow2 = [
	  {
	    content: 'home.why-join-us.reason4.content',
	    icon: faCommentsDollar,
	  },
	  {
	    content: 'home.why-join-us.reason5.content',
		 icon: faGlobe,
	  },
	  {
	     content: 'home.why-join-us.reason6.content',
		 icon: faLeaf,
	  }
	];
	
	
const WhyJoinUs = props => {
	
	return (
		<Container fluid id="why-join-us">
     		  <Row className="pt-5 justify-content-center" md={"auto"}><Col className="d-flex justify-content-center">
     		  		<h1 className="fs-1 text-center title"><Trans i18nKey="home.why-join-us.title" /></h1>
     		  </Col></Row>
     		  <Row className="mb-5"><Col className="d-flex justify-content-center">
     		  		<h3 className="text-center subtitle"><Trans i18nKey="home.why-join-us.subtitle" /></h3>
     		  </Col></Row>

			  <Row className="mb-5">
     		  	{reasonsRow1.map((item, index) => (
     				<Col key={index} md={4}>
     					<Row className="justify-content-center mb-3"><Col className="d-flex justify-content-center mb-4">
     		  				{/*<div className="rounded-circle p-4" style={{backgroundColor: '#EE8037'}}><FontAwesomeIcon className="reason-icon" icon={item.icon}  /></div>*/}
     		  				<FontAwesomeIcon className="reason-icon" icon={item.icon}  />
     		  			</Col></Row>
     					<Row className="justify-content-center mb-3"><Col className="d-flex align-content-center p-3">
     		  				<h2 className="reason-content text-center"><Trans i18nKey={item.content} /></h2>
     		  			</Col></Row>
     				
	 	             </Col>  
	 	   			))}	
    		</Row>
    		
    		<Row>
     		  	{reasonsRow2.map((item, index) => (
     				<Col key={index} md={4}>
     					<Row className="justify-content-center mb-3"><Col className="d-flex justify-content-center mb-4">
     		  				{/*<div className="rounded-circle p-4" style={{backgroundColor: '#EE8037'}}><FontAwesomeIcon className="reason-icon" icon={item.icon}  /></div>*/}
     		  				<FontAwesomeIcon className="reason-icon" icon={item.icon}  />
     		  			</Col></Row>
     					<Row className="justify-content-center mb-3"><Col className="d-flex align-content-center p-3">
     		  				<h2 className="reason-content text-center"><Trans i18nKey={item.content} /></h2>
     		  			</Col></Row>
     				
	 	             </Col>  
	 	   			))}	
    		</Row>
    	</Container>
  );
  
}

export default WhyJoinUs;
