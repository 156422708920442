import React from "react";
import Modal from 'react-bootstrap/Modal'
//import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
//import PropTypes from 'prop-types';
import loaderImg from '../images/loader.gif';

import '../styles/Loader.css';

//loading component for suspense fallback
const Loader = props => {
	//const spinnerStyle = { /*position: "fixed", top: "40%", left: "43%"*/};

	return (
		<Modal 
			show={props.show}
			aria-labelledby="contained-modal-loader"
		    centered
		    backdrop="static"
		    keyboard={false}
			animation={false}
			className="loader-modal"
			>
			 <Row>
				<Col className="text-center">
					<Image src={loaderImg} />
				</Col>
			</Row>
		</Modal>
	);
};

// Specifies the default values for props:
Loader.defaultProps = {
  show: true
};


export default Loader;