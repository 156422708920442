import React, {useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';

//import Container from 'react-bootstrap/Container';

import Header from "../components/Header";
import MainSection from "../components/MainSection";
import WhyJoinUs from "../components/WhyJoinUs";
import TransformCustomerExperience from "../components/TransformCustomerExperience";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import TryItOut from "../components/TryitOut";

//import DismissableFeedback from "../components/DismissableFeedback";

//import { isArrayWithLength, isAllowedRoute } from "../helpers/commons";
import { ValidationError } from "../helpers/custom-errors";

import { useConfig } from "../services/use-config";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const HomePage = props => {
	
 const [_error, _setError] = useState(null);
 const [_success, _setSuccess] = useState(null);
 const [_warning, _setWarning] = useState(null);
 const [_info, _setInfo] = useState(null);
 
 
 const { t } = useTranslation();
 const config = useConfig();
 
 useEffect(() => {
		let isMounted = true; 
		
		if (isMounted) {
			//Clear Error/Success every time location changes
			_setError(null);
			_setSuccess(null);
			_setWarning(null);
			_setInfo(null);
		}
			
		 return () => { isMounted = false };
		  
	}, [config.recaptchaSiteKey]);
 

 const onError = (error) => { 
	 if (!error) {
		 _setError(null);
		 return;
	 }
	 
	 if (error instanceof ValidationError) {		
		 _setError(error.message);
	 } else {
		_setError(t('error.api.general'));
	}
 };
 
 const onSuccess = (success) => {
	_setSuccess(success);
 };

const onWarning = (warning) => {
	_setWarning(warning);
 };

const onInfo = (info) => {
	_setInfo(info);
 };
 
  return (
	<Fragment>

		<Header 
			onError={onError}
			onSuccess={onSuccess}
			onWarning={onWarning}
			onInfo={onInfo}
		/>
		
		
		<MainSection />
		
		<WhyJoinUs />
		
		<TransformCustomerExperience />
		
		<AboutUs />
		
		{(config.recaptchaSiteKey) ?
			<GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
    			<TryItOut/>
    		</GoogleReCaptchaProvider>
    		:
    		null
    	}
		
		<Footer />
		
	 </Fragment>
	  
  );
}

export default HomePage;
