import React, {useState} from "react";
import { Trans, useTranslation } from 'react-i18next';

import Carousel from 'react-bootstrap/Carousel';
//import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import slideBg1 from '../images/slide-bg-1.jpg';
import slideBg2 from '../images/slide-bg-2.jpg';
import slideBg3 from '../images/slide-bg-3.jpg';
import slideBg4 from '../images/slide-bg-4.jpg';

import LazyImage from '../components/LazyImage'

import '../styles/MainSection.css';

const slides = [
	  {
		bgImage: slideBg1,
		text: "home.main-section.slide1.caption.text",
		button: "home.main-section.slide1.caption.button",
		href: "#try-it-out",
		blurhash: "L9EBHX4V68xT#8k7k;Rn00~VRis;"
	  },
	  {
		bgImage: slideBg2,
		text: "home.main-section.slide2.caption.text",
		button: "home.main-section.slide2.caption.button",
		href: "#try-it-out",
		blurhash: "LVHd:0~V?aM}t7t7oyxtWYozRjs+"
	  },
	  {
		bgImage: slideBg3,
		text: "home.main-section.slide3.caption.text",
		button: "home.main-section.slide3.caption.button",
		href: "#try-it-out",
		blurhash: "L5G*1l~q0000?^_20LE00KIU_N~V"
	  },
	  {
		bgImage: slideBg4,
		text: "home.main-section.slide4.caption.text",
		button: "home.main-section.slide4.caption.button",
		href: "#try-it-out",
		blurhash: "L8G*QI00^i0L#T%H}=-:4.r?%gNG"
	  }
];
	
const MainSection = props => {
	
  const [index, setIndex] = useState(0);
  
  const { t } = useTranslation();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
 
	return (
		<Carousel id="main-section" controls={false} indicators={false} slide={false} pause={false} interval={5000} activeIndex={index} onSelect={handleSelect}>
     		  {slides.map((item, index) => (
	 	             <Carousel.Item key={index}>
     					<LazyImage src={item.bgImage} hash={item.blurhash} alt={t(item.text)} className="d-block w-100" width={1920} height={768}  />
     					{/*<Image src={item.bgImage} className="d-block w-100" />*/}
        				<Carousel.Caption className="d-flex flex-column justify-content-center h-100">
          					<Row><Col>
          						<h2 className="display-2"><Trans i18nKey={item.text} /></h2>
          					</Col></Row>
          					<Row className="mt-5"><Col>
          						<Button href={item.href} variant="warning" size="lg"><Trans i18nKey={item.button} /></Button>
          					</Col></Row>
        				</Carousel.Caption>
      					</Carousel.Item>    
	 	                  
	 	   			))}
    	</Carousel>
  );
  
}

export default MainSection;
