import React from "react";
import { Trans, useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

import '../styles/AboutUs.css';

import image1 from '../images/about-us.jpeg';

	
const AboutUs = props => {
	
	const { t } = useTranslation();
	
	return (
		<Container fluid id="about-us">
     		<Container>
     		  <Row className="pt-5 mb-3" md={"auto"}><Col className="d-flex ">
     		  		<h2 className="fw-bold display-5 title"><Trans i18nKey="home.about-us.title" /></h2>
     		  </Col></Row>
     		  
     		  <Row className="mb-3"><Col md={10} className="d-flex">
     		  		<p className="fs-4 lead paragraph"><Trans i18nKey="home.about-us.paragraph1" /></p>
     		  </Col></Row>
     		  
     		  <Row className="mb-5"><Col md={10} className="d-flex">
     		  		<p className="fs-4 lead paragraph"><Trans i18nKey="home.about-us.paragraph2" /></p>
     		  </Col></Row>
     		  
     		  <Row className="pb-5"><Col md={6} className="d-flex justify-content-center">
     		  		<Image src={image1} alt={t("home.about-us.title")} rounded fluid/>
     		  </Col></Row>
			</Container>
    	</Container>
  );
  
}

export default AboutUs;
