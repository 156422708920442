import React,  { useState, useEffect } from 'react';
import log from 'loglevel';
import { Trans} from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Feedback from "../components/Feedback";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import MarkdownView from 'react-showdown';

import '../styles/PrivacyPolicy.css';


const PrivacyPolicyModal = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [privacyPolicy, setPrivacyPolicy] = useState(null);
	const [_error, _setError] = useState(null);
	
	const config = useConfig();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (config.privacyPolicy && config.privacyPolicy.path) {
			API.getPrivacyPolicy(config.privacyPolicy.path).then(response => {
				if (isMounted) setPrivacyPolicy(response);
			}).catch(error => {			
				log.error("Error Loading Privacy Policy: ", error.message);
				if (isMounted) _setError("home.privacy-policy-modal.failed");
			})
			.finally(() => {
		 		if (isMounted) setIsLoading(false);
		 	});
		 
		 	return () => { isMounted = false };
		 }
		  
	}, []);


	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-privacy-policy-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    id="privacy-policy"
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-privacy-policy-modal">
  					<h3><Trans i18nKey={"home.privacy-policy-modal.title"}>Privacy Policy</Trans></h3>
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body className="mb-3">
 				{(isLoading) ? 
					 <div className="d-flex justify-content-center">
						<Spinner animation="border" variant="secondary" />
					</div>
					: (_error) ? <Feedback feedback={_error} translate={true} type="danger" />
					: <MarkdownView className="markdown" markdown={privacyPolicy} />
				}
			</Modal.Body>
			<Modal.Footer>			 
          		<Button variant="secondary" className="close-button" onClick={props.onHide}><Trans i18nKey="home.privacy-policy-modal.close">Close</Trans></Button>	
          	</Modal.Footer>
		</Modal>
	);

}

export default PrivacyPolicyModal;
