import React, {useState, Fragment} from "react";

import { Blurhash } from "react-blurhash";


const LazyImage = props => {
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	
	return (
		<Fragment>
			<Blurhash
  				style={{display: isImageLoaded ? "none" : "inline" }}
  				className={!isImageLoaded ? props.className + " blurhash" : "blurhash"}
  				hash={props.hash}
  				resolutionX={32}
  				resolutionY={32}
  				punch={1}
			/>		
     		<img 
     			src={props.src}
     			onLoad={() => { setIsImageLoaded(true);}}
     			style={{display: !isImageLoaded ? "none" : "inline" }}
     			className={!isImageLoaded ? "" : props.className }
     			alt={props.alt}
     		/>
     	</Fragment>	);
};


export default LazyImage;