import React, {useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

//import Prompt from "../components/Prompt";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Feedback from "../components/Feedback";

import TryItOutFormFields from "../components/TryItOutFormFields";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import { Regex } from '../helpers/commons'

import { ValidationError } from "../helpers/custom-errors";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import '../styles/TryItOut.css';

setLocale({
	   mixed: {
		  default: 'home.try-it-out.form.validation.default',
		  required: 'home.try-it-out.form.validation.required',
	   }
	 });

const validationSchema = Yup.object().shape({
   name: Yup.string()
   		.max(255, 'home.try-it-out.form.name.validation.maximumlength')
   		.required('home.try-it-out.form.name.validation.required')
   		.default(() => ""),
   phone: Yup.string()
		.matches(Regex.MOBILE_PHONE, { message: 'home.try-it-out.form.phone.validation.format', excludeEmptyString: true })
   		.required('home.try-it-out.form.phone.validation.required')
   		.default(""),
   email: Yup.string()
   		.email('home.try-it-out.form.email.validation.format')
   		.required('home.try-it-out.form.email.validation.required')
   		.default(""), 
   message: Yup.string()
   		.max(1000, 'home.try-it-out.form.message.validation.maximumlength')
   		.required('home.try-it-out.form.message.validation.required')
   		.default(""),
   agreedPrivacyPolicy: Yup.boolean()
   		.oneOf([true],'home.try-it-out.form.agreedPrivacyPolicy.validation.required')
   		.default(() => false),
   recaptchaScore: Yup.number()
   		.default(() => 0),
   source: Yup.string()
   		.default(""), 
   medium: Yup.string()
   		.default("")
   		
});

	
const TryItOut = props => {
	
	const [_error, _setError] = useState(null);
    const [_success, _setSuccess] = useState(null);
   // const { executeRecaptcha } = useGoogleReCaptcha();
	
	const { t } = useTranslation();
	const config = useConfig();
	
	/*useEffect(() => {
		let isMounted = true; 
		
		if (isMounted) {
			//Clear Error/Success every time location changes
			_setError(null);
			_setSuccess(null);
		}
			
		 return () => { isMounted = false };
		  
	}, []);*/
	
	
	const onError = (error) => { 
	 	if (!error) {
		 	_setError(null);
		 	return;
	 	}
	 
	 	if (error instanceof ValidationError) {		
		 	_setError(error.message);
	 	} else {
			_setError(t('error.api.general'));
		}
 	};
 	
 	const onSuccess = (success) => {
		_setSuccess(success);
 	};
	
	const applyInvitation = (values) =>
		new Promise((resolve, reject) => {
			API.applyInvitation(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
		});
  
	
	let initialValues = validationSchema.default();
	
	return (
		<Container fluid id="try-it-out">
			
     		<Container>
     		   <Row className="pt-5 justify-content-center" md={"auto"}><Col className="d-flex justify-content-center">
     		  		<h1 className="fs-1 text-center title"><Trans i18nKey="home.try-it-out.title" /></h1>
     		  </Col></Row>
     		  
     		  <Row className="mb-3 justify-content-center"><Col className="d-flex justify-content-center">
     		  		<h4 className="text-center subtitle"><Trans i18nKey="home.try-it-out.subtitle" /></h4>
     		  </Col></Row>
     		  
     		  <Container>
				<Feedback feedback={_error} type="danger" />
				<Feedback feedback={_success} type="success" />
			  </Container>
     		
     		  <Row className="pb-5">
     		  	<Col>
     		  		<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						onError(null);
    						onSuccess(null);
    						    						
    						applyInvitation(values)
    							.then((response) => {
    								actions.resetForm() //Workaround to set dirty back to true
    								onSuccess(response.success);
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Apply Invitation Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Applying Invitation : ", error.message);
    								}
    								
    								onError(error);
    							})
    						
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue, setFieldError}) => (	
		      	<Form className="form" noValidate>
		      		{/*<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={JSON.stringify({
		      		           "title": t("home.try-it-out.form.unsubmitted-request-modal.title"),
		      		           "bodyText": t("home.try-it-out.form.unsubmitted-request-modal.body"),
		      		           "confirmText": t("home.try-it-out.form.unsubmitted-request-modal.confirm"), 
		      		           "cancelText": t("home.try-it-out.form.unsubmitted-request-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		        			   "when": compareJSON(initialValues, values)
		      		          }
		      		      )
		      		   }
		      		/>*/}
		      		
		      		<TryItOutFormFields 
							values={values} 
							errors={errors} 
							onChange={handleChange} 
							onError={onError}
							setFieldValue={setFieldValue}
							setFieldError={setFieldError}
							isSubmitting={isSubmitting}
						/>
	    			    
		      	</Form>	
		      )}
		      </Formik>
     		  	
     		  			
     		  	</Col>
     		 </Row>
     		  
     		 
			</Container>
    	</Container>
  );
  
}

export default TryItOut;
