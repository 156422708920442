import React, { Suspense } from 'react';
import { Routes, Route, useLocation, createBrowserRouter, RouterProvider} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HomePage from "./HomePage";
import NotFoundPage from "./NotFoundPage";

import Loader from "../components/Loader";
import ErrorBoundary from "../components/ErrorBoundary";

import { ProvideConfig } from "../services/use-config";


/*const useQuery = () => { 
	  return new URLSearchParams(useLocation().search);
};*/

const AppRoutes = props => {
	
	return (
		<Routes>
	     	<Route path="/" element={<HomePage/>} />	   
	     	<Route path={"*"} element={<NotFoundPage />} />  	
	     </Routes>
	);
}

const router = createBrowserRouter([
  	{ path: "*", Component: AppRoutes },
]);

const Root = props => {

	const { i18n } = useTranslation();
	
	return (
		<ProvideConfig>	
	     	<RouterProvider router={router} />
	   </ProvideConfig>	
	  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <Suspense fallback={<Loader />}>
    	<ErrorBoundary>
    		<Root />
    	</ErrorBoundary>
    </Suspense>
  );
}

