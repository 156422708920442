import React from "react";
import { Trans} from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../styles/Footer.css';
	
const Footer = props => {
	
	return (
		<Container fluid id="footer">
     		  <Row className="pt-5 justify-content-center"><Col className="d-flex justify-content-center">
     		  		<p className="fs-4 lead text-center paragraph"><Trans i18nKey="home.footer.allrightsreserved" /></p>
     		  </Col></Row>
    	</Container>
  );
  
}

export default Footer;