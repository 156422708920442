import React, {Fragment} from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { useTranslation } from 'react-i18next';

const FormControlErrors = props => {

	const { t } = useTranslation();
	
	if (!props.errors) {
		return null
	}
	
	return (
		<Fragment>
		{ (!props.block) 
			? <FormControl.Feedback className="text-left" type="invalid">{t(props.errors)}</FormControl.Feedback>
			: <div className="text-left invalid-feedback" style={{display: "block"}}>{t(props.errors)}</div>
		}
		</Fragment>
	)
};

export default FormControlErrors;