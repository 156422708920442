import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import i18n from './i18n';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';

//Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

//Importing the Fontawesome CSS
//import "font-awesome/css/font-awesome.css";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

//Strict Mode is usefull during development and it does not apply in production
root.render(<React.StrictMode><App tab="home"/></React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
