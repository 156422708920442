/* eslint-disable no-undef */
import { ValidationError, HttpError } from "../helpers/custom-errors";
import { isArrayWithLength, isAsyncSupported } from "../helpers/commons";
import log from 'loglevel';


const __BACKEND_API_PREFIX = "/app";

function appendPrefix(path) {
	return __BACKEND_API_PREFIX + path;
}

function sendHttpRequest(method, url, async, body) {
  
  let prefixedUrl = appendPrefix(url);

  //First check if fetch/async/await is supported
  if (window.fetch && async && isAsyncSupported()) {
	
	let fetchParams = {};
	fetchParams.method = method;
	fetchParams.accept = "application/json";
	fetchParams.body = body;

	return fetch(prefixedUrl, fetchParams)
   		.then(checkStatus);
  } else {

	
  //If fetch/await is not supported, we use XMLHttpRequest instead
  return new Promise(function(resolve, reject) {
    var xhr;
    var checkStatusXHR = function() {
      let error = new HttpError("GENERIC", xhr.status, `HTTP Error ${xhr.responseText}`);

	  try {

			let data;

			//if NOT an attachment, interpret response as json
        	if (xhr.getResponseHeader('Content-Disposition') && xhr.getResponseHeader('Content-Disposition').indexOf('attachment') > -1) {
				data = new Blob([xhr.response]);
        	} else {
				data = JSON.parse(xhr.responseText);
			}
		
			if (xhr.status >= 200 && xhr.status < 299) {
				resolve(data);
			} else if (xhr.status >= 400 && xhr.status < 499) { //Http Client Error
				//Check if code attribute has been properly set
				if (data.code) {
					//Check if AuthError
					if (data.code.indexOf("auth-") !== -1) {
						error = new AuthError(data.code, xhr.status, data.message);
					} else { 
						error = new ValidationError(data.code, xhr.status, data.detail, data.message);
					}
				} 
	    	} 

		} catch(err) {
			//Do nothing here. This block exists only to intercept json parse exception
			//Proper error handler should happen in block bellow
			
			log.error("backend-api.checkStatusXHR: " + err);
			
    	}

		reject(error);
	};

    xhr = new XMLHttpRequest();
    if (async) {
      xhr.onreadystatechange = function() {
        if (xhr.readyState !== 4) {
          return;
        }
        checkStatusXHR();
      };
    }
    xhr.open(method, prefixedUrl, async);
    xhr.send(body);
    if (!async) {
      checkStatusXHR();
    }
  });
}
}

async function checkStatus(response) {
	
	//Initially, we set default HttpError with generic error message
	let error = new HttpError("GENERIC", response.status, `HTTP Error ${response.statusText}`);
	
	// Then we to process/parse response body in json format
	// If it fails, a standard Error/Exception will be thrown and handled in the catch statement
	try {
		
		let data;

		//if NOT an attachment, interpret response as json
		
        if (response.headers.get('Content-Disposition') && response.headers.get('Content-Disposition').indexOf('attachment') > -1) {
			data = await response.blob();
        } else {
			data = await response.json();
		}

		//const jsonData = await response.json();
		
		if (response.ok) {
			return data;
		} else if (response.status >= 400 && response.status < 499) { //Http Client Error
			//Check if code attribute has been properly set
			if (data.code) {
				//Check if AuthError
				if (data.code.indexOf("auth-") !== -1) {
					error = new AuthError(data.code, response.status, data.message);
				} else { 
					error = new ValidationError(data.code, response.status, data.detail, data.message);
				}
			} 
	    } 

	} catch (err) {
		//Do nothing here. This block exists only to intercept json parse exception
		//Proper error handler should happen in block bellow
		
		log.error("backend-api.checkStatus: " + err);
	}
	
	//Finally Error is throw to be handled in upper level
	throw error;
}


async function checkStatusPrivacyPolicy(response) {
	
	//Initially, we set default HttpError with generic error message
	let error = new HttpError("GENERIC", response.status, `HTTP Error ${response.statusText}`);
	
	// Then we to process/parse response body in json format
	// If it fails, a standard Error/Exception will be thrown and handled in the catch statement
	try {
		
		let data = await response.text();
		
		if (response.ok) {
			return data;
		} 

	} catch (err) {
		//Do nothing here. This block exists only to intercept exception
		//Proper error handler should happen in block bellow
		
		log.error("backend-api.checkStatusPrivacyPolicy: " + err);
	}
	
	//Finally Error is throw to be handled in upper level
	throw error;
}



function objectToFormData(obj, blackList) {
	var fd = new FormData();
	var formKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property) && (!isArrayWithLength(blackList) || !blackList.includes(property))) {
			formKey = property;
			fd.append(formKey, obj[property]);
		}
	}

	return fd;    
};

function objectToQueryParameters(obj, blackList) {
	var queryString = "";
	var filterKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property) && (!isArrayWithLength(blackList) || !blackList.includes(property))) {
			filterKey = property;
			if (queryString) queryString = queryString.concat("&");
			else queryString = queryString.concat("?");
			queryString = queryString.concat(filterKey, '=', encodeURIComponent(obj[property]));
		}
	}

	return queryString;    
};

function getConfig() {
   
   return fetch(appendPrefix('/config'), {
	  accept: "application/json"
   })
   .then(checkStatus);

   //Force Syncronous Request
   //return sendHttpRequest('GET', '/config', false);
}


function updateLanguage(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/lang'), {
		method: 'post',
		body: form
	})
	.then(checkStatus);
}

function verifyRecaptcha(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/captcha'), {
		method: 'post',
		body: form
	})
	.then(checkStatus);
}

function applyInvitation(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/apply'), {
		method: 'post',
		body: form
	})
	.then(checkStatus);
}

function getPrivacyPolicy(path) {

	return fetch('/assets/'+path, {
	  accept: "text/plain"
    })
    .then(checkStatusPrivacyPolicy);

}


const API = { getConfig, updateLanguage, getPrivacyPolicy, verifyRecaptcha, applyInvitation };

export default API;
